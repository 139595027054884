.bar-container {
  width: 100%;
}

.idle-minutes .bar-line-container {
  background: #3ac26c;
}

.line-container {
  max-width: 95%;
}

.idle-minutes .bar-container .bar-line-container {
  margin-top: 0px;
  color: #fff;
  font-size: 2px;
  padding: 9px 0;
}

.idle-minutes .bar-label {
  float: right;
  text-align: right;
  background-color: #edeef2;
}

.bar-label-name {
  width: 20%;
  display: flex;
  align-items: center;
  padding: 2px;
}

.bar {
  display: inline-block;
}

.percentage-data {
  display: inline-block;
  width: 80%;
}

.inline-block {
  display: inline-block;
}

.circle-radialbar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 0px;
  line-height: 7px;
  text-align: center;
  padding: 5px;
}

.bar-label-name .circle-radialbar {
  padding-left: 5px;
}

.pink {
  background: #f7abb3;
}

.ash {
  background: #b0b1ba;
}

.yellow {
  background: #f7b854;
}

.cyan {
  background: #5ac8b7;
}

.purple {
  background: #c0e9d1;
}
