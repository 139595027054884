.header-dashboard {
  display: block;
  padding-top: 10px;
}

.header-dashboard-section {
  border-right: 1px solid #ccc;
  height: auto;
  padding: 5px;
}

.header-dashboard-section1 {
  margin-left: 10px;
}

.range_container {
  background-color: #ddd;
}

.range_percentage {
  background-color: #ff0000;
}

.range_percentage_green {
  background-color: #27c26c;
}

.headline {
  font-size: 12px;
  font-weight: 600;
}

.i_button {
  padding-left: 6px;
  cursor: pointer;
}

.dashboard-section-heading {
  padding-top: 10px;
}

.table-hidden {
  display: none;
}

.table-height {
  margin-top: 7.9px;
  height: 326.9px !important;
  width: 100% !important;
  overflow: auto;
}

.table th, .table td{
  vertical-align: inherit !important;
}
.row-height {
  height: 360px !important;
  overflow: auto;
}

.padding-right-zero {
  padding-right: 0 !important;
}

.inline-block {
  display: inline-block;
}

.circular.inline-block {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.green {
  border: 2px solid #3dc26c;
}

.red {
  border: 2px solid #e63045;
}

.dashboard-section-text {
  padding-left: 5px;
}

.dashboard-section-body {
  padding-top: 15px;
}

.dashboard-section-body .col {
  font-size: 10px;
  font-weight: 600;
}

.hide_from_reports {
  background-color: #fbe3ac;
  border-radius: 20px;
  font-size: 11px;
  padding: 0px 5px !important;
  font-size: 12px;
  width: max-content;
  margin-left: -22px;
}

.col.dashboard-section-footer {
  text-align: right;
  font-size: 12px;
  text-decoration: underline;
  position: absolute;
  bottom: 1px;
  color: #b4b4c1;
}

.col.dashboard-section-header {
  text-align: left;
  font-size: 12px;
  text-decoration: underline;
  color: #b4b4c1;
}

.col.dashboard-section-heading {
  padding-top: 10px;
}

.rbc-events-container div {
  background-color: green !important;
}

.time-track-section {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1025px) {
  .table-height td:before {
    content: attr(data-th);
    display: block;
    font-weight: bold;
    margin: 0 0 2px;
    color: #000;
    left: 0 !important;
    top: 0 !important;
    width: auto !important;
  }
  .table-height td {
    display: flex !important;
    justify-content: flex-end !important;
    padding: 5px !important;
    border: 0 !important;
    border-bottom: 1px solid #edeef2 !important;
  }
  .table-height tr {
    padding: 5px !important;
    border: 1px solid #edeef2
  }
}

.abc svg {
  height: 350px !important;
}

.abc > div > div > div {
  height: 350px !important;
}