.nav-item > .btn {
    margin: 0px 5px;
}

.p-accordion-header-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

.daterangepicker.opensright:before {
    left: 190px;
}

.daterangepicker.opensright:after {
    left: 190px;
}

/**
 * Styles for custom elements
 */

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-na {
    cursor: not-allowed !important;
}

/* React tag input custom css */
.react-tagsinput-input {
    width: auto !important;
}
.form-control-alternative.form-control.react-tagsinput-input {
    height: auto !important;
}

/**
 * Styles for Feeze UI - STARTS
 */
@keyframes spin {
    0% {
        transform: translateZ(0) rotate(0deg);
    }
    100% {
        transform: translateZ(0) rotate(360deg);
    }
}
.freeze-ui {
    position: fixed;
    cursor: wait;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: #fff;
    /* opacity: 0.8; */
    /* transition: opacity 0.25s; */
}
.freeze-ui.is-unfreezing {
    opacity: 0;
}
.lds-grid-spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #b2dd4c;
    animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}
@keyframes lds-grid {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

/**
 * Styles for Feeze UI - ENDS
 */

/**
  * Cards header for manage teams
  */
/* bg-white border-0 m-0 p-0 card-header teams-card-header */

/* .nav-pills .nav-item:not(:last-child) {
    padding-right: 1rem; -- REMOVE
} */

/**
 * Prime React Datatable
 */

.p-datatable .p-datatable-thead > tr > th {
    background-color: white !important;
    color: #b2c48a !important;
    font-size: 15px !important;
    padding: 0.6rem !important;
    font-weight: 700 !important;
}

.p-datatable .p-datatable-tbody > tr {
    /* font-weight: 500 !important; */
}

.team-member__tile {
    text-transform: capitalize !important;
    padding-left: 5rem !important;
}

li.p-multiselect-item {
    text-transform: capitalize !important;
}

.add_company__c-size-input {
    position: absolute !important;
    height: 1px !important;
    width: 1px !important;
    border: 0 !important;
    overflow: hidden !important;
}

.add_company__c-size-btn {
    color: #352929;
    background: #ffffff;
    border: 1px solid #35382e;
    font-size: 0.875rem;
    padding: 0.7rem 1rem;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.add_company__c-size-btn_selected {
    color: #ffffff;
    background: #b2dd4c;
    border: 1px solid #b2dd4c;
    font-size: 0.875rem;
    padding: 0.7rem 1rem;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.company_select-btn {
    display: flex !important;
    flex-direction: column !important;
    max-height: 60vh;
    overflow: auto;
}

.company_select-btn .p-button {
    margin: 10px !important;
    border: 1px solid #ced4da !important;
    border-radius: 6px !important;
}

.add-button {
    padding: 0.5rem 1.5rem !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    height: fit-content;
    justify-content: center;
}

.view-button {
    position: absolute !important;
    right: 10px;
    bottom: 10px;
    padding: 0.5rem 1rem !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    height: fit-content;
    justify-content: center;
}
#exampleSelectMulti {
    height: auto !important;
    padding-left: 2px !important;
    position: initial;
}


.ck.ck-powered-by.ck.ck-powered-by.ck.ck-powered-by {
    display: none !important;
}
.ck.ck-icon {
    z-index: 0 !important;
}
.ck.ck-content {
    min-height: 300px;
}

.main-content {
    height: 100vh;
    overflow: hidden;
}

.fc-scrollgrid {
    position: relative;
    z-index: 0;
  }

.card > .p-datatable-wrapper {
    height: 24vh ;
    max-height: 25vh !important;
    overflow: auto !important;
}

.p-datatable-wrapper {
    height: auto ;
    max-height: 62vh !important;
    overflow: auto !important;
}

.p-tag {
    font-size: 0.6rem !important;
}

.pi {
    font-size: 12px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem !important;
    font-size: 13px !important;
}

.first-col {
    min-width: 2vw;
    padding-left: 10px;
}
.second-col-card {
    min-width: 15vw;
    text-wrap: wrap;
}
.second-col {
    /* min-width: 20vw; */
    max-width: 20vw;
    text-wrap: wrap;
    text-align: start !important;
}
.third-col {
    min-width: 30vw;
    max-width: 250px;
}
.forth-col {
    min-width: 6vw;
}

.fifth-col {
    width: 10vw;
}
.td-start {
    text-align: start !important;
}
.p-datatable-emptymessage {
    height: 56vh !important;
}
.p-datatable-tbody > .p-datatable-emptymessage > td {
    text-align: center !important;
}
.main-content .navbar-top{
    /* z-index: 1000 !important; */
    height: 80px !important;
}
.space-between {
    justify-content: space-between;
}
.elipsis {
    overflow: hidden;
    /* text-overflow: ellipsis; */
}

.content {
    margin-top: 85px;
}
.main-content-child {
    height: 100vh;
    overflow: auto;
}
.footer {
    padding: 1rem 0 !important;
}

.add-request-btn {
    padding: 0.5rem !important;
    font-weight: bold !important;
}

.ck.ck-editor__editable .image {
    z-index: 0 !important;
}
.ck-toolbar__items{
    z-index: 0;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .timelog-card-table.p-datatable {
        table {
            thead, tbody.p-datatable-tbody {
                tr {
                    th {
                        display: table-cell !important;
                    }
                }
            }
        }
        table {
            tbody.p-datatable-tbody{
                .p-column-title {
                    display: none !important;
                }
            }
        }
    }
    .task-page-table.p-datatable {
        table {
            thead {
                display: table-header-group;
            }
            tbody.p-datatable-tbody {
                display: table-row-group;
            }
            thead, tbody.p-datatable-tbody {
                tr {
                    position: inherit;
                    display: table-row;
                    th, td {
                        display: table-cell;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }
    .td-start {
        text-align: right;
    }
    .second-col {
        white-space: break-spaces;
        max-width: 100%;
    }
    .third-col {
        white-space: break-spaces;
        max-width: 100%;
    }
    .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
        margin-right: 35px;
        display: block ;
        width: 35%;
        text-align: left;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        display: flex;
        width: 100% !important;
        align-items: center;
        justify-content: space-between;
    }
    .card-body .teamTable > tr > td {
        display: flex;
        width: 100% !important;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 0.3rem;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .teamTable > tr > td:nth-of-type(1):after {
        content: "Sr No.";
    }
    .teamTable > tr > td:nth-of-type(2):after {
        content: "Date";
    }
    .teamTable > tr > td:nth-of-type(3):after {
        content: "Present";
    }
    .teamTable > tr > td:nth-of-type(4):after {
        content: "Absent";
    }
    .teamTable > tr > td:nth-of-type(5):after {
        content: "Working Hrs. Not Completed";
    }

    .add-request-btn {
        padding: 0.3rem !important;
        font-weight: bold !important;
        font-size: 0.875rem !important;
    }

    .p-datatable .p-datatable-tbody > tr > td:not(:last-child) {
        border: 1px solid #dee2e6;
        border-width: 0 0 1px 0;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        border-width: 0 !important;
    }

    .teams-invite-division {
        justify-content: space-evenly;
    }
    .invite-member-button {
        min-width: 54.333333% !important;
    }

    .teamsTable thead > tr {
        position: relative;
        top: 0;
        left: 0;
    }

    .teamsTable .p-datatable-tbody > tr {
        background: #ffffff;
        color: #495057;
        transition: box-shadow 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .teamsTable .p-datatable-tbody > tr > td {
        display: block;
    }
}
.teamsTable .p-datatable-header {
    display: flex !important;
    justify-content: center !important;
    color: #b2c48a !important;
    font-size: 15px !important;
    text-transform: uppercase !important;
}

.teamsTable .p-datatable-thead {
    display: none !important;
}

@media only screen and (max-width: 768px), (min-device-width: 425px) {
    .button-container {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .btn-title {
        font-size: 0.875rem;
        text-align: start;
        padding-top: 20px;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: 0.5rem !important;
    }
} 

@media only screen and (max-width: 425px) {
    .teamTableDots .dropdown-menu {
        left: -55px !important;
        min-width: 7rem;
        padding: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .filter-buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100% !important;
    }

    .p-input-icon-left {
        width: 100%;
    }

    .p-input-icon-left > .p-inputtext {
        width: 100%;
    }

    .p-input-icon-left {
        width: 100%;
    }

    .p-input-icon-left > .p-inputtext {
        width: 100%;
    }

    .teamsTable th:nth-child(1) {
        display: none !important;
    }
    .teamsTable th:nth-child(3) {
        display: none !important;
    }

    .teamsTable .p-datatable-tbody > tr > td {
        border-width: 0 !important;
    }
}

@media only screen and (max-width: 1440px) {
    .teamsTable .p-datatable-thead > tr > th {
        min-width: -webkit-fill-available;
    }
}

.teamsTable .p-column-header-content {
    display: block !important;
}

.teamsTable td:nth-child(1) {
    width: 3% !important;
}
.teamsTable td:nth-child(2) {
    text-align: left !important;
}
.teamsTable td:last-child {
    width: 3% !important;
}

.teamTableExpander {
    max-width: 12% !important;
}

.teamTableDots {
    max-width: 28% !important;
}

.teamTableDots .dropdown-menu {
    left: -50px;
    min-width: 7rem;
}

@media only screen and (max-width: 1035px), (min-device-width: 425px) {
    .teams-invite-division {
        justify-content: space-evenly;
    }
}

/* .company_select-btn .p-button:last-of-type {
    border-radius: 6px !important;
}
.company_select-btn .p-button:first-of-type {
    border-radius: 6px !important;
} */

.card-min-hight {
    min-height: 90px;
}
.request-action-btn {
    padding: 10px !important;
    font-size: 0.875rem !important;
}
