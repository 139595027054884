thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
  background-color: #f7fafc;
}

thead button.ascending::after {
  font-family: "Font Awesome 5 Free";
  content: "\f0dd";
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  font-family: "Font Awesome 5 Free";
  content: "\f0de";
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}
.top-item-options {
  grid-template-columns: repeat(5, minmax(0, 2fr));
  display: flex;
  align-items: center;
}
.search-div > input.search-input {
  height: 30px;
  width: 420px;
  background: white;
  border: 1px solid #80808075;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 18px;
  padding-left: 5px;
  font-size: 0.875rem;
}

.select-assignee,
.select-status {
  width: 200px;
}
.css-3w2yfm-ValueContainer {
  flex-wrap: unset !important;
}

.taskbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 42px 42px 10px 42px;
  flex-wrap: wrap;
  /* height: 440px; */
  /* margin-top: 80px; */
}

.task-table .p-paginator-left-content {
  margin-right: 0 !important;
}
.task-table .p-button.p-button-icon-only {
  width: auto !important;
  padding: 0 !important;
}
.task-table .p-paginator .p-paginator-current {
  height: auto !important;
  font-size: 0.825rem !important;
  padding: 0.5rem !important;
  margin-left: auto !important;

}
.task-table .p-paginator .p-paginator-first,
.task-table .p-paginator .p-paginator-prev,
.task-table .p-paginator .p-paginator-next,
.task-table .p-paginator .p-paginator-last {
  min-width: auto !important;
  height: auto !important;
}
.task-table .p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem !important;
  height: 2rem !important;
}
.task-table .p-paginator .p-dropdown{
  height: 2rem !important;
  margin-right: auto !important;
}
.task-table .p-paginator .p-dropdown .p-dropdown-label{
  padding: 0 0 0 0.5rem;
}
.task-table .p-dropdown{
  align-items: center !important;
}
.task-table .p-dropdown .p-dropdown-trigger{
  width: 2rem !important;
}

.task-table .p-paginator-right-content{
  margin-left: 0 !important;
}
