.image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin: 0;
  background-color: rgb(220, 216, 216);
  border-radius: 5px;
  margin-bottom: 20px !important;
  height: fit-content;
  max-width: 200px;
}

.expandable-image {
  width: 100%;
  transition: transform 0.3s ease;
  z-index: 1;
  border-radius: 5px;
}

.image-text {
  padding: 5px;
  font-size: 20px;
  text-align: center;
  color: #333;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 3em;
  overflow: hidden;
}

.image-container.expanded {
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80vw;
  max-width: 80vw;
  height: auto;
  z-index: 100;
}

.image-container.expanded .expandable-image {
  width: 100%;
  max-height: 60vh;
  object-fit: contain;
}

.image-container.expanded .image-text {
  font-size: 26px;
  max-height: 200px;
  opacity: 1;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-text.expanded-text {
  transition: all 0.3s ease;
}
